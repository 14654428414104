import './aboutus.css';
import Header from "../../Header/header"; 
import Footer from "../../Footer/footer";
import Button from '../../Assets/Button/button';

function AboutUs() {

      function handleClick() {
            console.log("button clicked")
      };

    return (
      <div className="app">
        <div className="header">
            <Header/>
        </div>
        <div className="violet">
              <h1>About Us</h1>
              <p>We are the largest school based examination conducted by a student fraternity all over India.</p>
        </div>
        <div className="desc">
        <div className="description-1">
              <h2>
                What is Technothlon?
              </h2>
              <p>
              Technothlon is an international school championship organized by the student fraternity of IIT Guwahati. Technothlon began in 2004 in a small room with an aim to "Inspire Young Minds" . Starting on our journey with a participation of 200 students confined to the city of Guwahati, over the past 20 years we have expanded our reach to over 400 cities all over India and various centers abroad. Now Technothlon spans its wings in 30+ countries internationally. Technothlon is not just an exam but it is an enthralling journey on the path of logic which will test your logical and creative thinking to its limit.The championship is organized over 2 rounds: Prelims and Mains.
              </p>
        </div>
        <div className="sep"></div>
        <div className="description-2">
              <h2>What makes our paper so special?
              </h2>
              <p>No prerequisites are required for the exam i.e. you don't need to go through any special preparation for this exam. The exam aims for developing critical thinking and analytical skills among students. According to us, the beauty of the creative and analytical process involved in solving a puzzle can be enjoyed the best.
              </p>
        </div>
        <div className="sep"></div>
        <div className="description-3"> 
              <p><b>1. Prelims:</b> is a written preliminary examination that takes place in numerous schools all over India in July. It involves no prerequisites or general knowledge, and students have to rely on their logical and analytical thinking skills alone. The question paper is generally of 2 and ½ hours in length and will have various sections like math, puzzles, code crunchers, and more.
              </p>

              <p><b>2. Mains:</b> is an event-based competition, conducted during Techniche, the Techno-Management Festival of IIT Guwahati. The top 25 Teams from each squad compete against each other in challenging events designed to test their innovation, creativity, and imagination. These events do not require any prerequisite knowledge and, any extra knowledge required is taught to the students by us. The winners of Technothlon are crowned based on their performance in these events. In addition to this, students are also free to attend the numerous competitions, lectures, workshops, and music performances of Techniche.
              </p>
        </div>
        </div>
        
        < div className="voilet-main">
        <div className="l-violet">
              <h1>Exam Structure</h1>
              <p>This year students will participate in Teams of two. There are two squads:</p>
              <p><b>1. Junior-Squad:</b> Students of classes 9th and 10th
              </p>
              <p><b>2. Hauts-Squad:</b> Students of classes 11th and 12th
              </p>
              <h1>Modes of Registration </h1>
              <h4>There are 2 modes of registration - Online and Offline. The interested students can register themselves for the examination online or offline.</h4>
              <p><b>1. Offline -</b> Technothlon is conducted over various cities and centers with the help of city representatives. The city representatives are responsible to collect the registrations offline. Participating schools can collect the registration details themselves and then send the details to heads .</p>
              <p><b>2. Online -</b> Registration has now closed!</p>
              <div className="gap"></div>

              <div className="reg-button">
              {/* <Button color= "#00E86B" text="Register Online" onClick={() => {window.location.href="/teamregister"}} /> */}
              </div>
              
              </div>
             
             <Footer/>

             
            
        
        </div>
        
        
      </div>
    );
  }
  
  export default AboutUs;