import './contactus.css';
import Header from "../../Header/header"; 
import Footer from "../../Footer/footer";
import Button from '../../Assets/Button/button';
// import uv from '../../Assets/heads/utkarsh.jpg'
// import sk from '../../Assets/heads/siva.jpg'
// import sc from '../../Assets/heads/sandeep.jpg'
// import sg from '../../Assets/heads/sarvagna.jpg'
// import sm from '../../Assets/heads/sarvesh.jpg'
// import rk from '../../Assets/heads/rahulk.jpg'
// import hn from '../../Assets/heads/hrishikeshan.jpg'

import cv from '../../Assets/heads/charvi3.jpeg'
import sk from '../../Assets/heads/shubham2.jpeg'
import pj from '../../Assets/heads/Poojitha.jpg'
import vr from '../../Assets/heads/Varshini.jpg'
import lb from '../../Assets/heads/lalith1.jpeg'
import jk from '../../Assets/heads/jayant1.jpeg'
import ek from '../../Assets/heads/Ekshit.jpeg'



function ContactUs() {
  const contactsNorth = [
    { name: "Jaismeen Kaur", number: "80547 77060" },
    { name: "Tara Chand", number: "7056202923" },
    { name: "Ayush Sharma", number: "9817145616" },
    { name: "Kritagya", number: "9555226024" },
    { name: "jasvindar singh", number: "7417294042" },
    { name: "Garima Kalra", number: "8800545664" },
    { name: "Saanvi Goel", number: "8700343526" },
    { name: "Divyam Kulshrestha", number: "9310640781" },
    { name: "Samiksha Mitra", number: "9650392220" },
    { name: "Raj Gaurav", number: "9667782966" },
    { name: "Kartik Bisht", number: "7505641236" },
    { name: "Aryashi Tripathi", number: "8004654139" },
    { name: "Kavya Choudhary", number: "8957374450" },
    { name: "Himanshu", number: "9548687823" },
    { name: "Gaurav", number: "9667782966" },
    { name: "Sneha", number: "9306387841" }
  ];
  const contactsSouth = [
    { name: "Naga Jeshwanth Sreeram", number: "8660803634" },
    { name: "Parimi Sujatha", number: "8074489877" },
    { name: "Srija Pallampati", number: "9358612347" },
    { name: "Srinija Jonnakuti", number: "9866904151" },
    { name: "Jagu mohan Sri", number: "7386790171" },
    { name: "Sanjeev Krishnaa", number: "7975431003" },
    { name: "Ch.Moni praneeth", number: "8978145665" },
    { name: "Vivek", number: "8332814625" },
    { name: "PASALA EKSHITH SAI GUNAKAR", number: "9515364418" },
    { name: "Tarimala Revanth Reddy", number: "9652242567" },
    { name: "Prashanth Rachamalla", number: "8885495314" },
    { name: "Praveesh Peddagoni", number: "8074681155" },
    { name: "Ch. Sriram Satvik", number: "7569664109" },
    { name: "Chanikya", number: "8309072859" },
    { name: "Likith Srinivas", number: "7675011374" },
    { name: "V.Navya Sri", number: "8688493138" },
    { name: "Tadepalli Vinodini", number: "8790427566" },
    { name: "Pasupuleti Sai Manikanta", number: "7075032651" },
    { name: "Neha Sellappan Devadas", number: "9965799991" },
    { name: "Sharanya M", number: "7899496285" },
    { name: "Mathangi", number: "8123962557" },
    { name: "REDDY SIVA PRAJITH", number: "8333952217" },
    { name: "Varshith Grandhi", number: "8328260215" },
    { name: "SATHINENI VARSHINI", number: "8106226713" },
    { name: "D Gowtham Reddy", number: "8885362673" },
    { name: "Bindhu vislavath", number: "7207163711" },
    { name: "Nagarjun Reddy Sunki", number: "8688605323" },
    { name: "Lalithkumar B S", number: "6379749909" },
    { name: "Poojitha Reddy Roddam", number: "9063910805" },
    { name: "Bindu bk", number: "9972363206" },
    { name: "Namratha", number: "7989704770" },
    { name: "Ashish", number: "9121521199" },
    { name: "Akshay", number: "9133116018" },
    { name: "Harsha vardhan", number: "8106191690" },
    { name: "B.Sathwik", number: "6281221900" },
    { name: "Hemasree Ambala", number: "7989140836" },
    { name: "Akshay Bobbala", number: "9959562245" },
    { name: "K.Mahitha", number: "8309521983" },
    { name: "Sutheertha Enugandula", number: "7075561044" },
    { name: "Manideep ram gunje", number: "6303865227" },
    { name: "Kamaleshwari T S", number: "7826867068" },
    { name: "Pranav Vishal Deshmukh", number: "9347141625" },
    { name: "Mani Teja", number: "9741668749" },

  ]
  const contactsEast = [
    { name: "Vaibhav Sonowal", number: "7002303858" },
    { name: "Arpit Ranjan", number: "8409285656" },
    { name: "abhiraj mandal", number: "9674397828" },
    { name: "Irfan Hussain", number: "9394716799" },
    { name: "Ayush Kumar", number: "8709878907" },
    { name: "Himanshu Kumar", number: "8292912831" },
    { name: "Anubhav Anand", number: "7209514757" },
    { name: "Chukhu nyia loma", number: "6909604268" },
    { name: "Chaitanya Kumar", number: "7903561477" }
  ];
  const contactsCentral = [
    { name: "Aman Gupta", number: "9752222575" },
    { name: "Mohit Nagraj", number: "9340923056" },
    { name: "Vedant Mudiraj", number: "7869656432" },
    { name: "Garv Roy Choudhary", number: "9826070016" },
    { name: "Nimesh Gurjar", number: "8109997938" },
    { name: "Nikunj Sharma", number: "9302293083" },
    { name: "Piyush Jain", number: "9770519705" },
    { name: "Kedar Kisan", number: "6003776308" },
    {name:"Chinmay Pankaj Torvi",number : "9392871533"},
     { name:"Taes Padhihary", number:"9439670156"}
  ];
  const contactsWest = [
    { name: "Rishi Agrawal", number: "9351127432" },
    { name: "Jayant", number: "8003674745" },
    { name: "Aagam Chheda", number: "" },
    { name: "Freya", number: "7480028161" },
    { name: "Toshit", number: "6354642173" },
    { name: "Shrish", number: "7028729331" },
    { name: "Dhruv Pansuriya", number: "8780323875" },
    { name: "Palash", number: "9422142455" },
    { name: "Rahul Yadav", number: "9928007339" },
    { name: "Naman Karwa", number: "93777 80309" }
  ];
    return (
      <div className='App'>
        <Header/>
        
        <div className="cityrp-main">
        <div className="navy">
  
            <h1>Contact Us</h1>
            
            </div>

        </div>
       
        
        <div className="cityrp-main">
        <div className="head-headings"><p>City Representatives</p>
        </div>
        <div className="cityrp">
          <div className="zone">
            <h2>North Zone</h2>

            <div className="zone-name">
            {contactsNorth.map(contact => (
                  <div key={contact.number}>
                    <p style={{ fontWeight: "600" }}>{contact.name}</p>
                    <p>{contact.number}</p>
                  </div>
                ))}
            </div>
          
          </div>
          
          <div className="zone"><h2>South Zone</h2>
          <div className="zone-name">
          {contactsSouth.map(contact => (
                  <div key={contact.number}>
                    <p style={{ fontWeight: "600" }}>{contact.name}</p>
                    <p>{contact.number}</p>
                  </div>
                ))}
            </div></div>
          <div className="zone"><h2>East Zone</h2>
          <div className="zone-name">
          {contactsEast.map(contact => (
                  <div key={contact.number}>
                    <p style={{ fontWeight: "600" }}>{contact.name}</p>
                    <p>{contact.number}</p>
                  </div>
                ))}

            </div></div>
          <div className="zone"><h2>West Zone</h2>
          <div className="zone-name">
          {contactsWest.map(contact => (
                  <div key={contact.number}>
                    <p style={{ fontWeight: "600" }}>{contact.name}</p>
                    <p>{contact.number}</p>
                  </div>
                ))}
            </div></div>
          <div className="zone"><h2>Central Zone</h2>
          <div className="zone-name">
          {contactsCentral.map(contact => (
                  <div key={contact.number}>
                    <p style={{ fontWeight: "600" }}>{contact.name}</p>
                    <p>{contact.number}</p>
                  </div>
                ))}
            </div></div>
        </div>
        
        
        </div>
        <div className="contact">
        <div className="head-heading"><p>Meet Our Heads</p></div>
        <div className="main-head">
        <div className="heads">
          
          <div className="head-pic">
            <img src={cv} alt="" />
            
          </div>
          <div className="abt-head">
          <h2>Charvi Sri Koppisetti</h2>
          <p>North Zone Head</p>

          </div>
          
        </div>
        
        <div className="heads">
          
          <div className="head-pic">
            <img src={vr} alt="" />
            
          </div>
          
          <div className="abt-head">
          <h2>Varshini Sathineni
          </h2>
          <p>West Zone Head</p>

          </div>
        </div>
        
        <div className="heads">
          
          <div className="head-pic">
            <img src={pj} alt="" />
            
          </div>
          <div className="abt-head">
          <h2>Poojitha Reddy</h2>
          <p>South Zone Head</p>
              </div>
        </div>
        </div>
        <div className="main-head">
        <div className="heads">
          
          <div className="head-pic">
            <img src={lb} alt="" />
            
          </div>
          <div className="abt-head">
          <h2>
        Lalithkumar Banglore Sreenivasan 
          </h2>
          <p>North Zone Head</p>
     
          </div>
        </div>
        
        <div className="heads">
          
          <div className="head-pic">
            <img src={jk} alt="" />
            
          </div>
          <div className="abt-head">
          <h2>Jayant Kumar</h2>
          <p>West Zone Head</p>
          
          
              </div>
        </div>
        
        <div className="heads">
          
          <div className="head-pic">
            <img src={ek} alt="" />
            
          </div>
          <div className="abt-head">
          <h2> Ekshith sai Gunakar Pasala</h2>
          <p>South Zone Head</p>

          </div>
        </div>
        </div>
        <div className="main-head">
        <div className="heads">
          
          <div className="head-pic">
            <img src={sk} alt="" />
            
          </div>
          <div className="abt-head">
          <h2>Shubham Kumar Soni</h2>
          <p>East Zone Head</p>
          
          </div>
        </div>
        </div>
        
       
        <div className="cont-description">
              <h2>
                For further queries mail us at
                 technothlon@iitg.ac.in/ technothlon.iitg@gmail.com
              </h2>     
        </div>
        </div>
        
        
        
        <Footer/>
      </div>
    );
  }
  
  export default ContactUs;