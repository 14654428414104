const Registeration = () => {
  return (
    <>
      <h2>Registeration</h2>
      <br />
      <h2>City Code: 6969</h2>
      <h2>City Name: Delhi</h2>
      <div className="school-info">
        <div>
          <p>Lorem, ipsum.</p>
          <p>Lorem, ipsum.</p>
          <p>Lorem, ipsum.</p>
        </div>
        <button>Download Sheet</button>
      </div>
    </>
  );
};

export default Registeration;
