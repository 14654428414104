import './technopedia.css';
import Header from '../../Header/header';
import Footer from '../../Footer/footer';

function Technopedia() {
  const redirectUrl =
    process.env.NODE_ENV === 'production'
      ? 'https://technothlon.techniche.org.in/login'
      : 'http://localhost:3000/login';

  return (
    <div className="App">
      <div className="header">
        <Header />
      </div>
      <div className="technopedia-main">
        <div className="blue">
          <h1>Technopedia</h1>
          <p>Experience Technothlon before you actually attempt it!</p>
        </div>
        <div className="descriptionn">
          <p>
            Technopedia is the online module of Technothlon providing its students with an ultimate
            experience of the prelims beforehand. With the monthly quizzes, Technopedia aims at
            keeping the young minds involved in brainstorming questions and helping them prepare for
            the prelims. Only the students who have registered for Technothlon may log into
            Technopedia using either their email id or their roll number and password.
            <a href={redirectUrl}><b>Click here!</b></a>
          </p>
        </div>
        {/* Additional components or sections can be added here */}
        <div className="l-blue">
          <h1>Techno Coup D'oeil</h1>
          <p>Techno Coup D'oeil is our weekly question series running on social media Facebook and Instagram.</p>
          
        </div>

      </div>
      <div className="footer">
            <Footer />
          </div>
    </div>
  );
}

export default Technopedia;
